<template>
  <sd-container full>
    <card-demo/>
  </sd-container>
</template>

<script>
import CardDemo from '@/components/CardDemo'
export default {
  components: { CardDemo }
}
</script>

<style lang="scss" scoped>

</style>
